.main-section {
  display: flex;
  width: 100%;
  height: 100vh;
}

.section-left {
  background-color: #ffffff;
  width: 22%;
}

.section-right {
  background-color: #f7f7f2;
  width: 78%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
}

.path-section {
  height: 10%;
}

.child-section {
  height: 88%;
}

.caterogyPageScroll {
  height: calc(100vh - 335px);
}

.categoryImageDiv {
  height: 50px !important;
  width: 50px !important;
  background-color: #F1F9F3 !important;
}

.categoryImage {
  object-fit: cover !important;
}

.homepageModifierPadding {
  padding: 2rem 2rem 2rem 6rem;
}

.homepageModifierInput {
  height: 250px;
}

.homepageModifierCategoryScroll {
  height: calc(100vh - 270px);
}

.homepageModifierCategoryInventoryScroll {
  height: calc(100vh - 320px);
}

.categoryImageDiv {
  height: 50px !important;
  width: 50px !important;
  object-fit: "cover";
  background-color: #F1F9F3 !important;
}

.categoryImage {
  height: 40px !important;
  width: 40px !important;
  object-fit: cover !important;
}

.containerbg {
  height: calc(100vh - 120px);
}

.rowhight {
  height: 35px;
}

#row {
  height: 65px;
}

.center {
  text-align: center;
}

.Uploaded {
  height: "100px";
}

.Uploadimg {
  height: 212px;
  width: 213px;
}

#btnRegister {
  background: #12352f;
  color: yellow;
  height: 48px;
  width: 212px;
}

.unique {
  width: 129px;
  height: 71px;
}

.code {
  width: 129px;
  height: 48px;
}

.Textbar {
  width: 448px;
  height: 48px;
}

.smtext {
  height: 48px;
  width: 212px;
}

.box {
  height: 154px;
  width: 448px;
}

.margin {
  margin-left: 10%;
}

.margintext {
  margin-left: 19px;
}

.adhar-box {
  width: 448px;
  height: 154px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 6px;
}

.profile-box {
  width: 200px;
  height: 200px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 6px;
}

.homepageModifierInput {
  height: 500px;
}

.eye-icone {
  background-image: url("") !important;
}

.main-section {
  display: flex;
  width: 100%;
  height: 100vh;
}

.section-left {
  background-color: #ffffff;
  width: 22%;
}

.section-right {
  background-color: #f7f7f2;
  width: 78%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
}

.path-section {
  height: 10%;
}

.child-section {
  height: 88%;
}

.caterogyPageScroll {
  height: calc(100vh - 335px);
}

.categoryImageDiv {
  height: 50px !important;
  width: 50px !important;
  background-color: #F1F9F3 !important;
}

.categoryImage {
  object-fit: cover !important;
}

.homepageModifierPadding {
  padding: 2rem 2rem 2rem 6rem;
}

.homepageModifierInput {
  height: 250px !important;
}

.homepageModifierCategoryScroll {
  height: calc(100vh - 270px);
}

.homepageModifierCategoryInventoryScroll {
  height: calc(100vh - 320px);
}