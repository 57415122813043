.main-section {
  display: flex;
  width: 100%;
  height: 100vh;
}

.section-left {
  background-color: #ffffff;
  width: 22%;
}

.section-right {
  background-color: #f7f7f2;
  width: 78%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
}

.path-section {
  height: 10%;
}

.child-section {
  height: 88%;
}

.caterogyPageScroll {
  height: calc(100vh - 335px);
}

.categoryImageDiv {
  height: 50px !important;
  width: 50px !important;
  object-fit: "cover";
  background-color: #F1F9F3 !important;
  margin: auto;
}

.categoryImage {
  height: 40px !important;
  width: 40px !important;
  object-fit: contain !important;
}

.image-containerr-excell {
  position: relative;
}

.image-containerr-excell::before {
  content: "×";
  /* padding: 5px; */
  position: absolute;
  top: -5px;
  right: -15px;
  width: 15px;
  height: 15px;
  background-color: rgb(15, 14, 14);
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
  cursor: pointer;
}

.image-containerr-category {
  position: relative;
}

.image-containerr-category::before {
  content: "×";
  /* padding: 5px; */
  position: absolute;
  top: 0px;
  right: -6px;
  width: 15px;
  height: 15px;
  background-color: rgb(15, 14, 14);
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
  cursor: pointer;
}
