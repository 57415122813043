.sortb{
    height: 36px;
    width: 96px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    color: #3D3D3D;
}
.srch{
    height: 36px;
    width: 88px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #3D3D3D;
    border-radius: 4px;
}
.actiondetail{
    width: 88px;
    height: 36px;
    margin-left: 88px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FEBF22;
    background: #12352F;
    border-radius: 4px;
    outline-color: #FEBF22;

}
.rwidhtl{
    width: 998px;
    height: 77px;
    border-radius: 1px;
    border: solid 1px #E0E0E0;
   
}
.mrwidth{
    width: 1038px;
    height: 84px;
}
.fntdrk{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 0px;
    text-transform: uppercase;
    color: #3D3D3D;
}
.fntsmal{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3D3D3D;
}
.dots{
    font-size: 160%;
   line-height: 40%;
   color: black;
}
.paddingtops{
    padding-top: 8px;
}

.paddingtext{
    padding-left: 1%;
    margin-right: -2%;
}
.paddingfos{
    padding-left: 3%;
}

#scrollingup{
    height: 100%;
    width: 100%;
    border: 1px solidg #D9D9D9;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 420px;
}

.midspace{
    height: 499px;
    width: 1038px;
}
.widthselectes{
    width: 88px;
}
#srchsizing{
    margin-left: 16px;
}


