.main-section {
  display: flex;
  width: 100%;
  height: 100vh;
}

.section-left {
  background-color: #ffffff;
  width: 22%;
}

.section-right {
  background-color: #f7f7f2;
  width: 78%;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 3%;
}

.path-section {
  height: 10%;
}

.child-section {
  height: 88%;
}

.caterogyPageScroll {
  height: calc(100vh - 335px);
}

.bulkuploadPageScroll {
  height: calc(100vh - 200px);
}

.categoryImageDiv {
  height: 50px !important;
  width: 50px !important;
  background-color: #f1f9f3 !important;
}

.categoryImage {
  object-fit: cover !important;
}

.homepageModifierPadding {
  padding: 2rem 2rem 2rem 6rem;
}

.homepageModifierInput {
  height: 250px;
}

.homepageModifierCategoryScroll {
  height: calc(100vh - 270px);
}

.homepageModifierCategoryInventoryScroll {
  height: calc(100vh - 320px);
}

.image-container {
  position: relative;
}

.image-container::before {
  content: "×";
  position: absolute;
  top: -110px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: rgb(15, 14, 14);
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.image-containerr {
  position: relative;
}

.image-containerr::before {
  content: "×";
  position: absolute;
  top: -210px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: rgb(15, 14, 14);
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  cursor: pointer;
}


.multiple-image-height {
  height: calc(100vh -500px);
}

.bulkupload {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #292929;
}

.bulkuploadbutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background: #12352f;
  border-radius: 3px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "ss01" on;
  width: 88px;
  height: 25px;
  color: #febf22;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.visually-hidden {
  overflow: hidden;
}

.bulkuploadbutton-container {
  position: relative;
}

.bulkupload-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.bulkuploadmodelheader {
  margin: 1px;
  font-style: normal;
  font-weight: 700;
  font-size: 17.9749px;
  line-height: 21px;
  color: #292929;
}

.bulkuploadmodelsubheader {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #4c5c6b;
}

.bulkuploadchoosefile {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffc022;
}

.bulkuploadp {
  margin-top: 47px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #12352f;
}

.bulkuploadsinput {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #12352f;
}

.bulkuploadinputtag {
  margin-top: 15px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: #12352f;
}

.bulkuploadoption {
  width: 530px;
  height: 200px;
  border: 1px dashed #959595;
}

.bulkuploadoption-success {
  width: 530px;
  height: 200px;
  border: 1px dashed #959595;
}

.bulkuploadoption-danger {
  width: 530px;
  height: 200px;
  border: 1px dashed red;
}

.image_replace_option {
  
  width: 100px;
  border-width: 0.5px;
  border-color: white;
  border-radius: 0px 0px 5px 5px;
}

.ImageAddForm {
  border-radius: 5px 5px 0px 0px;
}