.horriLines{
    border-left: 1px solid #E0E0E0;
    height: 532px;
    left: 20%;
    
}
.underli{
    text-decoration-line: underline;
}
.txtfnt1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
}
.txtfnt2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #3D3D3D;
}
.locatft{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;
    font-feature-settings: 'ss01' on;
    color: #000000;
}
.lineh{
    line-height: 160%;
}
.btnhw{
    height: 48px;
    width: 212px;
}
#btnacc{
    margin-left: 23px;
}

.btnsize{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: 'ss01' on;
    color: #FFFFFF;
}

.btncls{
    background-color: #29AE13;;
}

.hrihts{
    width: 448px;
}

.heightt{
    height: 50px;
}
.boxmes{
    height: 231px;
    width: 448px;
}

@media only screen and (max-width:600px) {
    .mobile-horr{
      display: none;
    }
    .boxmes{
      width: 300px;
    }
    .mobile-message{
      text-align: justify;
      margin-bottom: 12px;
    }
    .heightt{
        height: 80px;
    }
    .hrihts{
        width: unset;
    }
    #btnacc{
        margin: 12px 0 0 0;
    }
  }