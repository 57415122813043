.societyListing-filterTab {
  height: 84px;
  display: flex;
  padding: 0px 23px 0px 23px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
}
.societyListing-table {
  margin-top: 15px;

  border-radius: 6px;
}
.table-th {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  padding: 15px;
  text-transform: uppercase;

  color: #3d3d3d;
}
.table-td {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  padding: 15px;

  color: #3d3d3d;
}
.societyonboardingmodel {
}
.societyonboardingmodel-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.caterogyPageScroll-internal {
  height: calc(100vh - 300px);
}
