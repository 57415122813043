* {
  margin: 0px;
  padding: 0;
}
.main-div {
  width: 100%;
  height: 100vh;
  background: #12352f;
}
.first-box {
  background: #ffffff;
  border-radius: 25px;
  width: 100%;
  min-height: 570px;
}
.second-boxd {
  width: 80%;
}
.hibee-logo {
  background-image: url("../../assets/images/logo.png");
  width: 170px;
  height: 44px;
  background-repeat: no-repeat;
}

.selected-dropdown-name {
  left: 36.81%;
  right: 56.11%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  font-feature-settings: "ss01" on;
  color: #12352f;
}

.dropdown-name {
  width: 86px !important;
  height: 17px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  font-feature-settings: "ss01" on;
  color: #12352f !important;
}
.label-style {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 17px;
  color: #3d3d3d;
  margin-bottom: 10px;
  margin-top: 15px;
}
.forget-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 1000;
  /* font-size: 16px; */
  line-height: 15px;
  text-decoration-line: underline;
  color: #7d7d7d;
}
.login-button {
   padding: 7px 40px;
   background: #12352f; 
   border-radius: 6px;
   margin-bottom: 3rem;
   font-family: "Inter";
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   color: #febf22;
}
