.categoryDetailSubCat {
  height: calc(100vh - 340px);
}

.categoryDetailImageConatiner {
  height: calc(100vh - 460px);
}
.categoryDetailImageConatiner-sub {
  height: calc(100vh - 550px);
}

.bg-light-blue {
  background-color: #F1F9F3 !important;
}

.image-containerr-sub-category {
  position: relative;
}

.image-containerr-sub-category::before {
  content: "×";
  /* padding: 5px; */
  position: absolute;
  top: 0px;
  right: -6px;
  width: 15px;
  height: 15px;
  background-color: rgb(15, 14, 14);
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 12px;
  border-radius: 50%;
  cursor: pointer;
}
