.border-bottom-blue {
    /* border-bottom: 5px solid #12352F; */
    width: 100px;
    color: #12352F !important;
  }
  

.nav-link{cursor: pointer;}
.nav-link.activev {
    border-bottom: 5px solid #12352F !important;
    font-weight: 700;
    transition: all ease-in 50ms; 
  }